@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }
  }
}

.blurcss
{
  filter : blur(64px) !important;
}

.video-responsive {
  width: 80%;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  
  left: 10%;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

/* infinity slider start */
.annimation{
  min-height: auto;
  display: grid;
  place-items: center;
}
.slider
{
  height: auto;
  margin : auto;
  margin-top: 20px;
  position : relative ; 
  width: 90%;
  display: grid; 
  place-items: center;
  overflow: hidden;
}
.slider span
{
  text-align: center;
  font-size: 18px;
  padding: 12px 0;
}
.slide-track
{
  display: flex;
  width: calc(250px * 34);
  animation: scroll 20s linear infinite;
}
.slide-track:hover
{
  animation-play-state: paused;
}

@keyframes scroll {
  0%
  {
    transform: translateX(calc(-100px * 13));
  }
  100%
  {
    transform: translateX(0);
  }
  
}

.slide{
  height: auto;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  perspective: 100px;
  border: 1px solid rgb(246, 246, 246);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  
  border-radius: 12px;
  margin: 5px;
}
.slider img{
  width: 30%;
  transition: transform 0.5s;
}
.slider img:hover{
  transform: translateZ(10px);
}

.slider::before, 
.slider::after
{
  background: linear-gradient(to right, rgba(255, 255,255,1)0%,
  rgba(255,255,255,0)100%);
  content: '';
  height: 100%;
  position: absolute;
  width: 15%;
  z-index: 2;
}
.slider::before
{
  left: 0;
  top: 0;
}
.slider::after{
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}
/* infinity slider end */
.list ul{
  list-style: disc !important
}
.list ul li
{
  list-style: disc !important;
  
}
/* tailwind css layers */
@layer components {
  .my__select {
    @apply outline-none border border-gray-300 rounded-md capitalize bg-white !cursor-pointer;
  }
}

@layer components {
  .my__rounded {
    @apply rounded-xl;
  }
}

/* tailwind css layers */
::webkit-scrollbar {
  width: 7px;
}

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: white;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.25);
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #a7a7a7;
  cursor: pointer !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}

body {
  margin: 0;
  /* font-family: 'Raleway', sans-serif !important; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E5E7EB !important;
  word-wrap: break-word;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.Mui-selected {
  background: #FFB125 !important;
  color: white !important;
  text-decoration: none !important;
}

.MuiTabs-indicator {
  display: none !important;
}

/* cross word clues and directions */

.clues {
  display: none !important;
}

.Mui-focused fieldset {
  border: none !important;
}

/* tabs buttons border radius changing */
.MuiTabs-flexContainer .MuiButtonBase-root {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

/* react slick button */
.slick-prev:before {
  color: black !important;
}

.slick-next:before {
  color: black !important;
}

/* react slick button */

.main{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.card{
    width: 100%;
    background: white;
    /* height: 50vh; */
    
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.half_1{
    width : 100%;
    height: 80px;
    display: flex;
    justify-content: center;
    border-radius: 15px 15px 0 0px ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.circle{
    width: 50px;
    height : 50px;
    border-radius: 50px;
    
    position: relative;
    top: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.half_2{
    width: 100%;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.half_2 h5 , .half_2 span{
    color: rgb(129, 129, 129);
}
.half_2 ul{
    display: flex;
    justify-content: space-evenly;
    
}
.css-fi2rf4-MuiPaper-root-MuiAppBar-root , .css-1k7uxmt, .css-18feom0-MuiPaper-root-MuiAppBar-root , .css-mvqsh2 {
    background: none !important;
}

.css-tlc64q-MuiPaper-root-MuiDialog-paper,.css-twia2z-MuiPaper-root-MuiDialog-paper {
    /* margin-top: 55px !important; */
    /* height: fit-content !important; */
    /* max-height: none !important; */
    overflow-y:visible !important;
    padding-bottom: 20px;
    
}
.css-hz1bth-MuiDialog-container{

    height: 100vh !important;
    overflow-y: scroll !important;
 }
.half_2 ul li{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100px;
}
.half_2 .schedule
{
    margin: 10px 0 0 0;
    text-transform: uppercase;
}
.time{
    margin: 20px 0;
    width: 80%;
    height: 50px;
    display: flex;
    color: white;
    font-size: large;
    letter-spacing: 3px;
    align-items: center;
    justify-content: center;
    background-color: rgb(92, 255, 92);
    border-radius: 30px;
}
.register
{
    margin: 0px 0 20px 0;
    width: 80%;
    height: 50px;
    display: flex;
    color: white;
    font-size: large;
    letter-spacing: 3px;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 136, 0);
    border-radius: 30px;
    font-weight: bold;
}

/* /////////////////////////////Headr section////////////////////////// */
.headerbgcolor {
  background-color: #FEF4E6;
}

/* ///////////////////////////////Hero section////////////////////////////////// */
.herobg {
  background-color: #ffffff;
}

.AboutQuizImg {
  width: 100%;

}

.heroimg {
  width: 100%;
  /* padding-left: 150px; */
  /* padding-top: 80px; */
  /* padding-left: 60px; */
}

.all-fields {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  margin: 60px 0px;
}

.each-field {
  width: 250px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 52px 35px;
  border-radius: 10px;
  cursor: pointer;
  background-color: #272B44;
  color: white;
}

.field-icon-box {
  width: 60px;
  height: 60px;
  padding-right: 20px;

}

.field-icon {
  width: 100%;

}

.field-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.vectoClock {
  width: 100%;
  opacity: 35%;
}

.houseImg {
  width: 70%;

  /* height: 300px!important; */
  /* position: relative; */
  opacity: 100%;

}

.bgclrHOUS {

  /* background-color: #272b44; */
}

.bluclr {
  background-color: none;
}

.VectorQuiz {
  /* padding-top: 100px; */
  padding-left: 100px;
}

.StartTest {
  background-color: #282C46;
  /* height: 327px; */
}

.StartQuiz {
  background-color: rgb(255, 255, 255);
  /* height: 327px; */

}

.aboutquiz1 {
  /* display: flex; */
  /* position: absolute;
  top: 50%;  position the top  edge of the element at the middle of the parent
  left: 50%; position the left edge of the element at the middle of the parent */

  /* transform: translate(-50%, -50%); */

}

.typo1 {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translate(-0%, -50%);
}

.typoCreate {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -100%);
}

.aboutquizcreate {

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

}

.brainImg {
  width: 100%;
  height: 645px;
  background-color: black;
  padding: 30px 0px;

}

.Accordion {
  margin-top: 50px;
  margin-bottom: 200px;
}

.iconCard {
  width: 20%;
  background-color: #ffffff;
  padding: 15px;
  border-radius: 30px;


}

.choseimg {


  width: 100%;
  background-color: #282C46;
  border-radius: 0px 200px 200px 0px;
}

.choseleft {
  display: flex;
  justify-content: space-around;
  flex-direction: row;

}

.choseusbgclr {
  background-color: #E5E5E5;

}

.iconImg {
  width: 30%;

}

/* .text{
  margin-left: 20px !important;
  margin-bottom: 40px !important;
} */
a {
  text-decoration: none;
  color: black;
}

ul {
  padding: 0px;
}

ul li {
  
  padding: 5px 0px;


}

.keyfeaturebgpadding {
  padding: 40px 0px;
}

/* /////////////////////////Quiz category///////////////////////////////// */
.quiz-category {
  text-align: center;
  margin: 60px 0px;
}

.allCategory {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}

.eachCategory {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  max-width: 150px;
  border-radius: 10px;
  background-color: #bdc6d2a3;
  color: white;
  margin: 10px;
  padding: 10px;

  cursor: pointer;
}

.img-container {
  display: flex;
  justify-content: center;

}

.title {
  text-align: center;
  text-transform: uppercase;
  color: black;
  font-size: 12px;

}

/* ////////////////////////////contact/////////////////////////// */

.ContactAll {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 50px 0px;
}

.eachContact {
  width: 100%;
  max-width: 350px;
  background-color: #C2DAFE;
  border-radius: 20px;
  padding: 20px;
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.contactImg {
  width: 50px;
  padding-right: 20px;
  text-align: center;
}


/* ////////////////////////textbook///////////////////////////////////// */
.textbook-icon {
  width: 100px;
  height: 100px;
  border-radius: 15px;
}

.textbookBorder {
  border: 1px solid;
  border-color: #425FEB;
}

/* ////////////////////////WHYtextbook///////////////////////////////////// */
.Why-right-card {
  background-color: #e28963;
  padding: 30px;
  border-radius: 20px;
}

.WhyTextBook-icon {
  width: 20%;
  background-color: #fdfdfd;
  padding: 20px;
  border-radius: 20px;

}

.col-3 {
  padding-top: 0 !important;
}

/* ////////////////////////Start Learning///////////////////////////////////// */
.ply-img2 {
  margin: 0px !important;
  margin-top: 60px !important;
  margin-left: 50px !important;
}

.startbg {

  position: relative;
  background-image: url("https://coolbackgrounds.io/images/backgrounds/index/ranger-4df6c1b6.png");
}

.startimg {
  position: absolute;
  top: -5rem;

}

@media (max-width: 768px) {

  /* /////////////////////////HERO SECTION/////////////////// */
  .all-fields {
    display: flex;
    flex-direction: column;
  }

  .field-icon {
    width: 100%;
  }

  .field-icon-box {
    width: 60px;
    height: 60px;
    padding-right: 0px !important;
  }

  .each-field {
    margin: 10px 0px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: space-around;
    width: 350px;

  }



  .request-button {
    padding: 6px 32px !important;

  }

  .ply-img {
    width: 4%;
    margin: 0 !important;
    margin-left: 138px !important;
    margin-top: 10px !important;
  }

  /* /////////////////////ABOUT QUIZ SECTION///////////////////// */
  .bulbImg {
    width: 100%;
    margin: 0;
  }

  .brainImg {

    height: 100%;



  }

  /* //////////////////////////textbook select ////////////////// */

  /* .textbook-box{
    display: flex;
    justify-content: center;

  } */
  .textbookBorder {
    border: none !important;
  }

  /* //////////////////////////Why Textbook Section ////////////////// */

  .Why-right-card {
    margin: 20px 0px;
  }

  .col-class {
    padding-top: 0 !important;
  }

  .col-3 {
    padding-top: 20px !important;
  }

  .WhyTextBook-icon {
    width: 30%;
    display: flex;
    justify-content: center;

  }

  .Why-right-card {
    display: flex;

    justify-content: center;
    flex-direction: column;
    align-items: center;
  }

  /* //////////////////////////start learning ////////////////// */
  /* .start-box{
    display: flex;
    flex-direction: column;
    align-items: center;
 
  }  */
  .request-button {
    width: max-content;
  }

  .ply-img2 {
    width: 6%;
    /* padding: 0 !important;
    margin: 0 !important; */
    margin-top: 62px !important;
    margin-left: 6px !important;
  }

  .startimg {
    display: none;
  }

  /* //////////////////////////Chose US ////////////////// */

  .choseleft {
    flex-wrap: wrap;
    margin-left: 0px !important;
    margin-top: 20px;
  }

  .heroimg {
    width: 100%;
    padding: 0;
    padding-top: 50px;

  }

  .choseimg {
    width: 100%;
    border-radius: 0px 0px 20px 20px;
  }

  .iconImg {
    margin-left: 0px !important;
    width: 70px;
    height: 70px;
    margin: 10px;
  }

  .leftIconData {
    display: flex;
    flex-direction: row;
    margin: 0px 25px;
  }

  .choseusbgclr {
    display: flex;
    flex-direction: row-reverse;
  }

  .iconText {
    font-size: 17px !important;
  }

  .VectorQuiz {
    padding-left: 40px;
  }

  /* .aboutquiz1{
      padding-top: 90px !important;
      padding-left: 180px !important;
      font-weight: bold !important;

    }
    .aboutquizcreate{
      padding-left: 100px !important;
      padding-top: 150px !important;
      font-weight: bold !important;
      letter-spacing: 2px !important;
    }
    .aboutquizplay{
      padding-left: 115px !important;
      padding-top: 150px !important;

      font-weight: bold !important;
      letter-spacing: 2px !important;
    } */


}

/* @media (max-width: 820px){
  .aboutquiz1{
    padding-top: 70px !important;
    padding-left: 380px !important;
    font-weight: bold !important;

  }
  .aboutquizcreate{
    padding-left: 240px !important;
    padding-top: 150px !important;
    font-weight: bold !important;
    letter-spacing: 2px !important;
  }
  .aboutquizplay{
    padding-left: 255px !important;
    padding-top: 150px !important;

    font-weight: bold !important;
    letter-spacing: 2px !important;
  }
} */
/* footer{
  color : white ;
} */
.footer
{
  width : 100%;
  display: flex;
  justify-content: space-evenly;
  background: #ffd58b;
  padding : 50px 0;
  font-size: 18px;
}
.footer div{
  width: 30%;
}
.footer img{
  width: 60%;
}
.footer .contact
{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.contact span{
  display: flex;
  gap: 1rem;
}
.bottom{
  width: 100%;
  height: 10vh;
  background: #FFB42E;
  display: flex;
  align-items: center;
  justify-content: center;
  
}
body {
  background: linear-gradient(to left, rgb(138, 52, 52), rgb(97, 97, 199));
}

.App {
  position: relative;
}

#spin,
#reset {
  /* position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    padding: .5rem 1rem; */
}

#spin:hover {
  color: rgb(0, 180, 120);
  border-color: rgb(0, 180, 120);
}

#spin:focus,
#reset:focus {
  outline: none;
}

/* #reset {
    position: absolute;
    bottom: 20vh;
    right: 35vw;
  } */

#reset:hover {
  color: red;
  border-color: red;
}



#readout {
  position: absolute;
  bottom: 7vh;
  left: 35vw;
}


#selector {
  position: absolute;
  top: 6rem;
  right: 47.5%;
  font-size: 40px;
  z-index: 3;
}

#result {
  font-size: 25px;
  color: rgb(0, 180, 120);
}